.blogPostWrapper {
  margin-bottom: 60px;
  .blogPostImg {
    img {
      width: 100%;
      -webkit-filter: grayscale(0);
      -moz-filter: grayscale(0);
      filter: grayscale(0);
      -webkit-transition: all 0.5s;
      -moz-transition: all 0.5s;
      transition: all 0.5s;
    }
  }

  .blogPostContent {
    ul {
      &.blogPostMeta {
        margin-top: 20px;
        display: flex;
        align-items: center;
        margin-bottom: 25px;
        li {
          color: #666;
          @media (max-width: 375px) {
            font-size: 12px;
          }
          &::before {
            content: '|';
            padding: 0px 15px;
            color: #ccc;
            @media (max-width: 375px) {
              padding: 0px 5px;
            }
          }
          &:first-child {
            &::before {
              display: none;
            }
          }
          &:nth-child(2) {
            &::before {
              display: none;
            }
          }
          img {
            height: 40px;
            width: 40px;
            border-radius: 50%;
            margin-right: 20px;
            @media (max-width: 375px) {
              margin-right: 10px;
            }
          }
        }
      }
    }
    h3 {
      font-size: 36px;
      font-weight: 400;
      margin-bottom: 20px;
      @media (max-width: 375px) {
        font-size: 30px;
      }
      a {
        color: #282e3f;
      }
    }
    h4 {
      margin-top: 20px;
      font-weight: 400;
    }
    p {
      line-height: 30px;
    }
    a {
      &.readmore {
        display: inline-block;
        font-size: 16px;
        color: #c0b596;
        font-weight: 600;
        text-transform: capitalize;
      }
    }
  }
  &:hover {
    .blogPostImg {
      img {
        width: 100%;
        -webkit-filter: grayscale(50%);
        -moz-filter: grayscale(50%);
        filter: grayscale(50%);
      }
    }
  }
}
